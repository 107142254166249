import { inject } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '@shared/models';
import { LuxonDatePipe } from '@shared/pipes';
import { DateTime } from 'luxon';

export function maxDateValidator(minDate: DateTime): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dateValue = control.value as DateTime | null | undefined;

        if (dateValue == null || dateValue <= minDate) {
            return null;
        }

        return {
            maxDate: { max: minDate, actual: dateValue },
        };
    };
}

export function withMaxDateValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);
        const luxonDatePipe = inject(LuxonDatePipe);

        return new Map<string, ValidationMessageResolver>([
            [
                'maxDate',
                (label, value) =>
                    translate.instant('validation.maxDate', {
                        label: label,
                        max: luxonDatePipe.transform(value['max']),
                    }),
            ],
        ]);
    };
}

import { inject, InjectionToken } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export type ValidationMessageResolver = (label: string, value: ValidationErrors) => string;

export type ValidationMessageFn = () => Map<string, ValidationMessageResolver>;

export const VALIDATION_MESSAGES = new InjectionToken<Map<string, ValidationMessageResolver>[]>('validation-messages');

export function injectValidationMessages(): Map<string, ValidationMessageResolver> {
    return new Map(
        inject(VALIDATION_MESSAGES)
            .map((i) => Array.from(i.entries()))
            .flat(),
    );
}

export const environment = {
    isProduction: false,
    apiUrl: 'https://dev.lamie.systems/portal-gateway/api/v1/',
    subscriptionKey: '97227f50-fd78-484d-8418-ac0994542de7',
    keycloak: {
        realm: 'Core',
        url: 'https://dev.lamie.systems/auth/',
        clientId: 'portal_dev_client',
    },
};

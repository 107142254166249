import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';

@Pipe({
    name: 'humanizeDuration',
    standalone: true,
})
export class HumanizeDurationPipe implements PipeTransform {
    private readonly translate = inject(TranslateService);

    public transform(date: DateTime | null | undefined): string | null {
        if (date == null) {
            return null;
        }

        const ms = date.diffNow().milliseconds;
        return humanizeDuration(ms, {
            language: this.translate.currentLang,
            units: ['d', 'h', 'm'],
            largest: 2,
            round: true,
        });
    }
}

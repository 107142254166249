import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { newGuid } from '../shared/utils';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationHttpInterceptor implements HttpInterceptor {
    private readonly user = inject(UserService);
    private readonly translate = inject(TranslateService);

    private readonly onlyApplyOnUrls = [environment.apiUrl];

    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.onlyApplyOnUrls.some((url) => httpRequest.url.startsWith(url))) {
            return next.handle(httpRequest);
        }

        const correlationId = httpRequest.headers.get('correlationId') ?? newGuid();

        const request = httpRequest.clone({
            headers: httpRequest.headers
                .set('subscription-key', environment.subscriptionKey)
                .set('correlationId', correlationId)
                .set('userPrincipalId', this.user.userPrincipalId())
                .set('lang', this.translate.currentLang),
        });

        return next.handle(request).pipe(
            catchError((error) => {
                console.error(error);
                return throwError(() => error);
            }),
        );
    }
}

import { inject } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageFn, ValidationMessageResolver } from '@shared/models';
import { LuxonDatePipe } from '@shared/pipes';
import { DateTime } from 'luxon';

export function minDateValidator(minDate: DateTime): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dateValue = control.value as DateTime | null | undefined;

        if (dateValue == null || dateValue >= minDate) {
            return null;
        }

        return {
            minDate: { min: minDate, actual: dateValue },
        };
    };
}

export function withMinDateValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);
        const luxonDatePipe = inject(LuxonDatePipe);

        return new Map<string, ValidationMessageResolver>([
            [
                'minDate',
                (label, value) =>
                    translate.instant('validation.minDate', {
                        label: label,
                        min: luxonDatePipe.transform(value['min']),
                    }),
            ],
        ]);
    };
}

import { DateTime } from 'luxon';
import { PersonType } from '../enums';

export function fullName(person: { firstName: string; lastName: string }): string {
    return [person.firstName, person.lastName].join(' ');
}

export function getPersonType(
    birthdate: DateTime,
    restriction: { ageLimitChild: number; ageLimitAdult: number },
): PersonType {
    const age = Math.abs(birthdate.diffNow('years').years);
    return age >= restriction.ageLimitChild ? 'Adult' : 'Child';
}

import { inject, Injectable, signal } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { filter, from, Observable, of } from 'rxjs';
import { User } from '../shared/models';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly keyCloakService = inject(KeycloakService);
    private readonly userService = inject(UserService);

    private _user = signal<User | null>(null);
    public user = this._user.asReadonly();

    public init() {
        const tokenInfo = this.keyCloakService.getKeycloakInstance().idTokenParsed;
        if (tokenInfo == null) {
            return;
        }

        const user: User = {
            email: tokenInfo['email'],
            firstname: tokenInfo['given_name'],
            lastname: tokenInfo['family_name'],
        };
        this.userService.setUser(user);

        console.info('User info: ', user);

        this.handleOnTokenExpired();
    }

    public login(): Observable<void> {
        return from(this.keyCloakService.login());
    }

    public isLoggedIn(): Observable<boolean> {
        return of(this.keyCloakService.isLoggedIn());
    }

    public logout(): Promise<void> {
        return this.keyCloakService.logout(window.location.origin);
    }

    private handleOnTokenExpired() {
        this.keyCloakService.keycloakEvents$
            .pipe(filter((event) => event.type === KeycloakEventType.OnTokenExpired))
            .subscribe(async () => {
                console.info('Token expired. Updating token...');

                try {
                    await this.keyCloakService.updateToken(0);
                    console.info('Token updated successfully');
                } catch (error) {
                    console.error('Error updating token: ', error);
                    this.logout();
                }
            });
    }
}

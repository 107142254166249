import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { definePreset, palette } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { LuxonDatePipe, LuxonDateTimePipe } from '@shared/pipes';
import { provideValidationMessages, withDefaultValidationMessages } from '@shared/utils';
import {
    withJMBGValidationMessages,
    withMaxDateValidationMessages,
    withMinDateValidationMessages,
} from '@shared/validators';
import { KeycloakAngularModule } from 'keycloak-angular';
import { providePrimeNG } from 'primeng/config';
import { DialogService } from 'primeng/dynamicdialog';
import { routes } from './app.routes';
import { AuthorizationHttpInterceptor } from './auth/auth-http.interceptor';
import { provideKeycloakAuth } from './auth/keycloak-initializer';
import { provideStartupService } from './startup.service';

const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(http, './i18n/', '.json');
};

const LamiePreset = definePreset(Aura, {
    semantic: {
        primary: palette('#017169'),
    },
});

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimationsAsync(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes, withComponentInputBinding()),

        provideHttpClient(
            withInterceptorsFromDi(), // TODO:
        ),

        provideKeycloakAuth(),
        importProvidersFrom([KeycloakAngularModule]),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true,
        },

        provideStartupService(),
        LuxonDatePipe,
        LuxonDateTimePipe,

        provideValidationMessages(
            withDefaultValidationMessages(),
            withJMBGValidationMessages(),
            withMinDateValidationMessages(),
            withMaxDateValidationMessages(),
        ),

        providePrimeNG({
            theme: {
                preset: LamiePreset,
                options: {
                    darkModeSelector: '.my-app-dark',
                    ripple: true,
                    cssLayer: {
                        name: 'primeng',
                        order: 'tailwind-base, primeng, tailwind-utilities',
                    },
                },
            },
        }),
        DialogService,

        provideTranslateService({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
};

import { EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VALIDATION_MESSAGES, ValidationMessageFn, ValidationMessageResolver } from '@shared/models';

export function showErrors(control: AbstractControl): boolean {
    return control.invalid && control.touched;
}

export function withDefaultValidationMessages(): ValidationMessageFn {
    return () => {
        const translate = inject(TranslateService);

        return new Map<string, ValidationMessageResolver>([
            ['required', (label) => translate.instant('validation.required', { label: label })],
            ['minlength', (label, value) => translate.instant('validation.minLength', { ...value, label: label })],
            ['maxlength', (label, value) => translate.instant('validation.maxLength', { ...value, label: label })],
            ['min', (label, value) => translate.instant('validation.min', { ...value, label: label })],
            ['max', (label, value) => translate.instant('validation.max', { ...value, label: label })],
        ]);
    };
}

export function provideValidationMessages(...validationMessages: ValidationMessageFn[]): EnvironmentProviders {
    return makeEnvironmentProviders(
        validationMessages.map((fn) => ({
            provide: VALIDATION_MESSAGES,
            useFactory: fn,
            multi: true,
        })),
    );
}

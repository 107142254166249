import { DateTime } from 'luxon';

const daysInMonth = (year: number) =>
    isLeapYear(year)
        ? [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        : [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export function getDateOfBirthFromJMBG(nationalIdentificationNumber: string | null): DateTime | null {
    if (nationalIdentificationNumber === null) {
        return null;
    }

    const arrJMBG = nationalIdentificationNumber.split('');
    const birthYear = getBirthYear(arrJMBG);
    const birthMonth = getBirthMonth(arrJMBG);
    if (birthYear === null || birthMonth === null) {
        return null;
    }

    const birthDay = getBirthDay(arrJMBG, birthYear, birthMonth);
    if (birthDay === null) {
        return null;
    }

    return DateTime.fromObject({ year: birthYear, month: birthMonth, day: birthDay });
}

export function isValidJMBG(jmbg: string) {
    if (jmbg.length === 0) {
        return null;
    }
    if (jmbg.length !== 13) {
        return { jmbgLengthError: true };
    }

    const arrJMBG = jmbg.split('');

    const birthYearInt = getBirthYear(arrJMBG);
    if (birthYearInt === null) {
        return { jmbgBirthYearError: true };
    }

    const birthMonthInt = getBirthMonth(arrJMBG);
    if (birthMonthInt === null) {
        return { jmbgBirthMonthError: true };
    }

    const birthDayInt = getBirthDay(arrJMBG, birthYearInt, birthMonthInt);
    if (birthDayInt === null) {
        return { jmbgBirthDayError: true };
    }

    if (!isValidControlNumber(arrJMBG)) {
        return { jmbgControlNumberError: true };
    }

    return null;
}

function getBirthYear(arrJMBG: string[]): number | null {
    const birthYear = arrJMBG.slice(4, 7).join('');
    const birthYearInt = Number(birthYear);
    if (birthYear[0] === '0') {
        return birthYearInt + 2000;
    } else if (birthYear[0] === '9') {
        return birthYearInt + 1000;
    } else {
        return null;
    }
}

function getBirthMonth(arrJMBG: string[]): number | null {
    const birthMonth = arrJMBG.slice(2, 4).join('');
    const birthMonthInt = Number(birthMonth);
    if (birthMonthInt > 12 || birthMonthInt < 1) {
        return null;
    }
    return birthMonthInt;
}

function isLeapYear(year: number): boolean {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}

function getBirthDay(arrJMBG: string[], birthYearInt: number, birthMonthInt: number): number | null {
    const birthDay = arrJMBG.slice(0, 2).join('');
    const birthDayInt = Number(birthDay);
    if (birthDayInt > daysInMonth(birthYearInt)[birthMonthInt - 1] || birthDayInt < 1) {
        return null;
    }
    return birthDayInt;
}

function isValidControlNumber(arrJMBG: string[]): boolean {
    let controlNumber =
        11 -
        ((7 * (Number(arrJMBG[0]) + Number(arrJMBG[6])) +
            6 * (Number(arrJMBG[1]) + Number(arrJMBG[7])) +
            5 * (Number(arrJMBG[2]) + Number(arrJMBG[8])) +
            4 * (Number(arrJMBG[3]) + Number(arrJMBG[9])) +
            3 * (Number(arrJMBG[4]) + Number(arrJMBG[10])) +
            2 * (Number(arrJMBG[5]) + Number(arrJMBG[11]))) %
            11);
    if (controlNumber > 9) {
        controlNumber = 0;
    }
    return controlNumber === Number(arrJMBG[12]);
}

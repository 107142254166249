import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, EnvironmentProviders, Injectable, Injector, makeEnvironmentProviders } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { TranslateService } from '@ngx-translate/core';
import { appIcons } from './app.icons';

export function provideStartupService(): EnvironmentProviders {
    return makeEnvironmentProviders([
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: (startupService: StartupService, injector: Injector) => () => startupService.load(injector),
            deps: [StartupService, Injector],
            multi: true,
        },
    ]);
}

@Injectable()
class StartupService {
    public async load(injector: Injector) {
        this.initTranslations(injector);
        this.initLocale();
        this.initIcons(injector);
    }

    private initTranslations(injector: Injector) {
        const translate = injector.get(TranslateService);

        const defaultLanguage = 'en';

        const availableLanguages = ['en', 'de'];

        const browserLanguage = navigator.languages
            .map((language) => language.split('-')[0])
            .find((language) => availableLanguages.includes(language));

        translate.setDefaultLang(defaultLanguage);
        translate.use(browserLanguage ?? defaultLanguage);
    }

    private initLocale() {
        registerLocaleData(localeDe);
    }

    private initIcons(injector: Injector) {
        const iconLibrary = injector.get(FaIconLibrary);
        iconLibrary.addIcons(...appIcons);
    }
}

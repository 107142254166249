import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export function provideKeycloakAuth(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: APP_INITIALIZER,
            useFactory: KeycloakInitializer,
            deps: [KeycloakService, AuthService],
            multi: true,
        },
    ]);
}

function KeycloakInitializer(keycloak: KeycloakService, authService: AuthService) {
    return async () => {
        await keycloak.init({
            config: {
                url: environment.keycloak.url,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.clientId,
            },
            initOptions: {
                onLoad: 'login-required',
            },
            loadUserProfileAtStartUp: false,
            bearerExcludedUrls: ['/i18n', '/img'],
        });

        if (!keycloak.isLoggedIn()) {
            await keycloak.login();
        }

        authService.init();

        return true;
    };
}

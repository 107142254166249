import { computed, Injectable, signal } from '@angular/core';
import { User } from '../shared/models';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user = signal<User>({ email: '', firstname: '', lastname: '' });

    public user = this._user.asReadonly();
    public userPrincipalId = computed<string>(() => this.user().email);

    public setUser(user: User) {
        this._user.set(user);
    }
}

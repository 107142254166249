import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronLeft,
    faCompass,
    faFileContract,
    faInfoCircle,
    faMinus,
    faTrashCan,
    faTriangleExclamation,
    faUser,
} from '@fortawesome/pro-light-svg-icons';

export const appIcons: IconDefinition[] = [
    faChevronLeft,
    faCompass,
    faFileContract,
    faInfoCircle,
    faMinus,
    faTrashCan,
    faTriangleExclamation,
    faUser,
];
